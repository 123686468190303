// globally accessible namedspaced js functions
// access these as attendee.function() to avoid conflicts with other libs/functions

var attendee = attendee || {

	helpscout_beacon: function (options) {
		// attendee beacon
		! function (e, t, n) {
			function a() {
				var e = t.getElementsByTagName("script")[0],
					n = t.createElement("script");
				n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
			}
			if (e.Beacon = n = function (t, n, a) {
					e.Beacon.readyQueue.push({
						method: t,
						options: n,
						data: a
					})
				}, n.readyQueue = [], "complete" === t.readyState) return a();
			e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
		}(window, document, window.Beacon || function () {});
		window.Beacon('init', 'ea18e588-ca97-40a9-a869-6a2b4163a7fb')


		$('a.hsbeacon').click(function (e) {
			window.Beacon('identify', (options));
			window.Beacon('open')
			return false
		});

	}

};